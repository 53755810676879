<template>
    <div>
        <CRow>
            <CCol lg="12" style="margin-bottom:10px">
                <CButton type="submit" size="sm" class="float-left btn-primary" @click="$router.go(-1)" ><CIcon name="cil-list"/> 목록</CButton>
                <form @submit.prevent="formSubmit" method="post">
                <CButton type="submit" size="sm" color="primary" class="float-right" :disabled="afterClick">
                    <CIcon name="cil-pencil"/>
                    저장
                </CButton>
                </form>
            </CCol>
        </CRow>
        <CRow>
            <CCol lg="12">
            <CCard>
                <CCardHeader>
                    <strong> OS </strong>
                </CCardHeader>
                <CCardBody>
                    <CRow form class="form-group mb-0 ml-1">
<!--                       <div v-for="(option, optionIndex) in osTypeOptions" :key="optionIndex">-->
<!--                            <CInputRadio class="ml-1"-->
<!--                                :value="option.value"-->
<!--                                :checked="appObfuscation.osType==option.value"-->
<!--                                @update:checked="() => appObfuscation.osType = option.value"-->
<!--                                @change="changeOsType()"-->
<!--                            />-->
<!--                            <label class="mr-2">-->
<!--                                <img-->
<!--                                    :src="$renderer('osType', 2, option.value)"-->
<!--                                    class="ml-4 mr-2"-->
<!--                                    width=35-->
<!--                                    height=35-->
<!--                                />-->
<!--                        </label>-->
<!--                        </div>-->
                      <CInputRadio class="ml-1"
                                   :value="0"
                                   :checked="appObfuscation.osType == 0"
                                   @update:checked="() => appObfuscation.osType = 0"
                                   @change="changeOsType"
                      />
                      <label class="mr-2">
                        <img
                          :src="$renderer('osType', 2, 0)"
                          class="ml-4 mr-2"
                          width="35"
                          height="35"
                        />
                      </label>
                    </CRow>
                    <!-- <CRow class="ml-2">
                    <CInputRadio class="ml-4"
                        v-for="(option, optionIndex) in osTypeOptions"
                        :key="optionIndex"
                        :label="option.label"
                        :value="option.value"
                        :checked="osType==option.value"
                        @update:checked="() => osType = option.value"
                    >
                    <template #label>
                        <label class="ml-1">
                            <iconAndroid v-if="option.value == '0'" width="30" height="30"/>
                            <iconApple v-if="option.value == '1'" width="30" height="30" />
                        </label>
                    </template>
                    </CInputRadio>
                    </CRow> -->
                </CCardBody>
            </CCard>
            <CCard>
               <CCardHeader>
                    <strong> 앱 정보 </strong>
               </CCardHeader>
                <CCardBody>
<!--                    <div v-if="showCertData && appObfuscation.osType=='0'">-->
<!--                        <CRow class="mb-3">-->
<!--                            <CCol sm="2">-->
<!--                                <strong>Google Play Store 여부</strong>-->
<!--                            </CCol>-->
<!--                            <CCol sm="6">-->
<!--                                <CSwitch-->
<!--                                    color="primary"-->
<!--                                    :checked.sync="appObfuscation.storeYn"-->
<!--                                    labelOn="ON"-->
<!--                                    labelOff="OFF"-->
<!--                                    />-->
<!--                            </CCol>-->
<!--                        </CRow>-->
<!--                        <CRow v-if="appObfuscation.storeYn" class="mb-1">-->
<!--                            <CCol sm="2">-->
<!--                                <strong>Google Play 인증서 지문(*)</strong>-->
<!--                                <CButton size="sm" color="primary" class="mt-2" @click="autoCertData">-->
<!--                                    이전 지문 불러오기-->
<!--                                </CButton>-->
<!--                            </CCol>-->
<!--                            <CCol sm="10">-->
<!--                                <CTextarea-->
<!--                                    horizontal-->
<!--                                    v-model="certData"-->
<!--                                    :value.sync="$v.appObfuscation.certData.$model"-->
<!--                                    :isValid="!$v.appObfuscation.certData.$error"-->
<!--                                    validFeedback="' : '는 공백으로 치환됩니다."-->
<!--                                    invalidFeedback="필수 입력입니다."-->
<!--                                />-->
<!--                            </CCol>-->
<!--                        </CRow>-->
<!--                    </div>-->
<!--                    <div v-if="showCertData && appObfuscation.osType=='1'">-->
<!--                        <CRow class="mb-3">-->
<!--                            <CCol sm="2">-->
<!--                            <strong>Apple App Store 여부</strong>-->
<!--                            </CCol>-->
<!--                            <CCol sm="6">-->
<!--                                <CSwitch-->
<!--                                    color="primary"-->
<!--                                    :checked.sync="appObfuscation.storeYn"-->
<!--                                    labelOn="ON"-->
<!--                                    labelOff="OFF"-->
<!--                                    />-->
<!--                            </CCol>-->
<!--                        </CRow>-->
<!--                    </div>-->
<!--                    <div v-if="appObfuscation.storeYn">-->
<!--                        <CRow>-->
<!--                            <CCol sm="2">-->
<!--                            <strong>앱 이름(*)</strong>-->
<!--                            </CCol>-->
<!--                            <CCol sm="6">-->
<!--                                <CInput-->
<!--                                    horizontal-->
<!--                                    :value.sync="$v.appObfuscation.appNm.$model"-->
<!--                                    :isValid="!$v.appObfuscation.appNm.$error"-->
<!--                                    invalidFeedback="필수 입력입니다."-->
<!--                                    :readonly="selectMode"-->
<!--                                />-->
<!--                            </CCol>-->
<!--                        </CRow>-->
<!--                        <CRow>-->
<!--                            <CCol sm="2">-->
<!--                            <strong>패키지명(*)</strong>-->
<!--                            </CCol>-->
<!--                            <CCol sm="6">-->
<!--                                <CInput-->
<!--                                    horizontal-->
<!--                                    :value.sync="$v.appObfuscation.packageNm.$model"-->
<!--                                    :isValid="!$v.appObfuscation.packageNm.$error"-->
<!--                                    invalidFeedback="필수 입력입니다."-->
<!--                                />-->
<!--                            </CCol>-->
<!--                        </CRow>-->
<!--                        <CRow>-->
<!--                            <CCol sm="2">-->
<!--                            <strong>버전(*)</strong>-->
<!--                            </CCol>-->
<!--                            <CCol sm="6">-->
<!--                                <CInput-->
<!--                                    horizontal-->
<!--                                    :value.sync="$v.appObfuscation.appVersion.$model"-->
<!--                                    :isValid="!$v.appObfuscation.appVersion.$error"-->
<!--                                    invalidFeedback="필수 입력입니다."-->
<!--                                />-->
<!--                            </CCol>-->
<!--                        </CRow>-->
<!--                    </div>-->
                    <div class="flex w-full h-screen items-center justify-center text-center" id="app" v-if="!selectMode"  >
                        <div class="p-12 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                            <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle"
                            class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="selectFile" :accept="appObfuscation.osType=='1'?'.ipa':'.apk, .aab'" hidden/>

                            <label for="assetsFieldHandle" class="block cursor-pointer" width="100%">
                            <div>
                                <CRow class="mt-2">
                                    <CCol sm=10>
                                    <Strong>이곳에 파일을 드래그하거나 <br>
                                    클릭하여 추가해주세요</Strong>
                                    </CCol>
                                    <CCol sm=2 >
                                    <CIcon width="40px" hidth="40px"
                                        :content="$options.icons.cibAddthis"
                                    />
                                    </CCol>
                                </CRow>
                            </div>
                            <slot name="fileLoading" v-if="fileLoading">
                                <loadingBar/>
                            </slot>
                            </label>
                        </div>
                    </div>
                    <CRow v-if="selectFile" class="mt-3">
                        <CCol sm="1" class="mr-3">
                            <CImg
                                :src="previewImgUrl"
                                block
                                class="mb-1"
                                width="130px"
                                height="130px"/>
                        </CCol>
                        <CCol sm="10">
                            <CRow>
                                <CCol sm="2">
                                <strong>앱 이름(*)</strong>
                                </CCol>
                                <CCol sm="5">
                                    <CInput
                                        horizontal
                                        :value.sync="$v.appObfuscation.appNm.$model"
                                        :isValid="!$v.appObfuscation.appNm.$error"
                                        invalidFeedback="필수 입력입니다."
                                        :readonly="selectMode"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="2">
                                <strong>패키지명</strong>
                                </CCol>
                                <CCol sm="5">
                                {{ selectFile.packageNm }}
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="2">
                                <strong>버전</strong>
                                </CCol>
                                <CCol sm="5">
                                {{ selectFile.appVersion }}
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="2">
                                <strong>파일</strong>
                                </CCol>
                                <CCol sm="10">
                                {{ selectFile.name }}
                                </CCol>
                            </CRow>
                            <CRow class="mb-1">
                                <CCol sm="2">
                                <strong>사이즈</strong>
                                </CCol>
                                <CCol sm="5">
                                {{ fileSize }} MB
                                </CCol>
                            </CRow>
                            <CRow class="mb-1">
                                <CCol sm="2">
                                <strong>OS</strong>
                                </CCol>
                                <CCol sm="5">
                                {{ selectFile.osTypeNm }}
                                </CCol>
                            </CRow>
                            <CRow class="mb-1" v-if="selectMode">
                                <CCol sm="2">
                                <strong>앱 다운로드</strong>
                                </CCol>
                                <CCol sm="5">
                                    <CButton
                                    square
                                    size="sm"
                                    @click="downloadApp()"
                                    class="btn-primary"
                                    >
                                    <CIcon
                                        :content="$options.icons.cilDataTransferDown"
                                    />
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>

<!--            <CCard>-->
<!--                <CCardHeader>-->
<!--                    <strong> 정책 설정 </strong>-->
<!--                </CCardHeader>-->
<!--                <CCardBody>-->
<!--                    <CRow>-->
<!--                        <CCol sm="10">-->
                            <!-- <CRow form class="form-group" >
                                <CCol tag="label" sm="3" class="col-form-label font-weight-bold">
                                    탐지정책
                                </CCol>
                            </CRow> -->
<!--                            <CRow form class="form-group">-->
<!--                                <CCol sm="3">-->
<!--                                    앱 위변조 탐지-->
<!--                                </CCol>-->
<!--                                <CSwitch-->
<!--                                    color="primary"-->
<!--                                    :checked.sync="appObfuscation.appTamperDetectYn"-->
<!--                                    labelOn="ON" -->
<!--                                    labelOff="OFF"-->
<!--                                    :disabled="selectMode"-->
<!--                                    />-->
<!--                            </CRow>-->
<!--                            <CRow form class="form-group">-->
<!--                                <CCol sm="3">-->
<!--                                    OS 위변조 탐지-->
<!--                                </CCol>-->
<!--                                <CSwitch-->
<!--                                    color="primary"-->
<!--                                    :checked.sync="appObfuscation.osTamperDetectYn"-->
<!--                                    labelOn="ON" -->
<!--                                    labelOff="OFF"-->
<!--                                    :disabled="selectMode"-->
<!--                                    />-->
<!--                            </CRow>-->
<!--                            <CRow form class="form-group">-->
<!--                                <CCol sm="3">-->
<!--                                    디버깅 탐지-->
<!--                                </CCol>-->
<!--                                <CSwitch-->
<!--                                    color="primary"-->
<!--                                    :checked.sync="appObfuscation.debugDetectYn"-->
<!--                                    labelOn="ON" -->
<!--                                    labelOff="OFF"-->
<!--                                    :disabled="selectMode"                                    -->
<!--                                    />-->
<!--                            </CRow>-->
<!--                        </CCol>-->
<!--                    </CRow>-->
<!--                </CCardBody>-->
<!--            </CCard>-->
            <slot name="loading" v-if="loading">
                <loadingBar/>
            </slot>
          </CCol>
        </CRow>
  </div>
</template>
<script>
import CIcon from '@coreui/icons-vue/src/CIconRaw.vue'
import { cilXCircle, cibAddthis, cilDataTransferDown} from '@coreui/icons'
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers, numeric, maxLength  } from "vuelidate/lib/validators"
export default {
    name: 'RegistObfuscation',
    icons: { cilXCircle, cibAddthis, cilDataTransferDown},
    mixins: [validationMixin],
    components:{
        CIcon
    },
    delimiters: ['${', '}'], // Avoid Twig conflicts
    data () {
        return {
            selectFile: null, // 파일 객체
            previewImgUrl: null, // 미리보기 이미지 URL
            response: null, // 파일 업로드후 응답값
            fileLoading: false, // 파일로딩바 활성화 여부
            loading: false, // 로딩바 활성화 여부
            selectMode: false,
            afterClick: false, // 버튼 클릭 직후 여부(버튼 중복 클릭되지 않도록)
            osTypeOptions: [{value:'0', label:'Android', img:'@/assets/icons/IconAndroid.png'}, {value:'1', label:'iOS', img:'@/assets/icons/IconApple.png'}],
            showCertData: true,
            appObfuscation: {
                appNm: "",
                appKey: "",
                // appTamperDetectYn: true, // 앱위변조탐지 여부
                // osTamperDetectYn: true, // OS위변조탐지 여부
                // debugDetectYn: true, // 디버깅탐지 여부
                // storeYn:false,
                certData: "",
                packageNm: "",
                appVersion: "",
                osType:"0",
            },
            certData:'',
            fileSize:0,
        }
    },
    props: {
    },
    created () {
    },
    mounted() {
        this.$v.appObfuscation.$touch()
    },
    watch:{
        certData(){
            this.$nextTick(() => {
                this.certData = this.certData.replace(/:+/g, ' ');
            })
        }
    },
    computed:{
    },
    validations: {
        appObfuscation: {
            appNm: {
                required,
            },
            packageNm: {
                required,
            },
            appVersion: {
                required,
            },
            certData: {
                required,
            }
        }
    },
    methods: {
        // 앱파일 변경 이벤트
        onChange() {
            this.clear()
            // this.selectFile = null // 앱정보 초기화
            // this.appNm = null // 앱 이름 초기화
            // this.previewImgUrl = null // 앱 아이콘 초기화
            // this.googlePlayAppSign = false // 구글플레이사인 초기화
            this.fileLoading = true  // 로딩바 활성화
            if (0 < this.$refs.selectFile.files.length) {
                let filename = this.$refs.selectFile.files[0].name
                let lastDot = filename.lastIndexOf('.');
                let fileExt = filename.substring(lastDot, filename.length).toLowerCase();
                if ((this.appObfuscation.osType == '1' && fileExt != ".ipa") ||
                    (this.appObfuscation.osType == '0' && (fileExt != ".apk" && fileExt != ".aab"))) {
                    if (this.osType == '0') alert("APK 또는 AAB 파일을 등록해주세요.");
                    else if (this.osType == '1') alert("IPA 파일을 등록해주세요.");
                    this.fileLoading = false  // 로딩바 비활성화
                    return
                }

                // if(this.$refs.selectFile.files[0].size> 50000000){
                //     //alert("50MB이하의 파일만 등록할 수 있습니다.")
                //      this.fileLoading = false  // 로딩바 비활성화
                //     return
                // }
                // 앱 정보 조회
                this.selectAppFileInfo(this.$refs.selectFile.files[0])
            }
            else{
                this.fileLoading = false  // 로딩바 비활성화
            }
        },
        async selectAppFileInfo(fileInfo) {

            //Form 필드 생성
            let form = new FormData()
            form.append("file", fileInfo) // 앱파일
            let payload = form
            try {
                // 서버에서 앱파일 디컴파일하여 앱정보 조회
                await this.$store.dispatch('appObfuscation/getAppFileInfo', payload,{
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                this.selectFile = fileInfo
                let appFileInfo = this.$store.state.appObfuscation.appFileInfo
                this.selectFile.packageNm = appFileInfo.packageNm
                this.selectFile.appVersion = appFileInfo.appVersion
                this.selectFile.osType = appFileInfo.osType
                this.selectFile.osTypeNm = (this.selectFile.osType=='0'?"ANDROID":"iOS")
                this.appObfuscation.appNm = appFileInfo.appNm
                this.fileSize = String((parseInt(this.selectFile.size)/1024/1024).toFixed(2)) // KB를 MB로 변경

                // 앱 아이콘 정보가 조회되지 않은 경우
                if(!appFileInfo.appIconFileNm){
                    alert("잘못된 앱파일입니다.")
                    this.selectFile=null
                }
                else{
                    this.previewImgUrl="/api/resources/app-file/icon?fileNm="+appFileInfo.appIconFileNm+"&tempYn=true"
                }
                this.fileLoading = false  // 로딩바 비활성화
            } catch(error){
                this.$response(error)
                this.fileLoading = false  // 로딩바 비활성화
            }
            return true
        },

        async formSubmit() {
            if(this.validate()){
                this.afterClick = true
                this.loading = true  // 로딩바 활성화
                // Form 필드 생성
                let form = new FormData()
                // if (!this.appObfuscation.storeYn&&this.selectFile) {
                    form.append("file", this.selectFile)
                    form.append("fileNm", this.selectFile.fileNm)
                // }
                // else{
                //     form.append("packageNm", this.appObfuscation.packageNm)
                //     form.append("appVersion", this.appObfuscation.appVersion)
                // }

                form.append("appNm", this.appObfuscation.appNm)
                form.append("osType", this.appObfuscation.osType)
                // form.append("storeYn", this.appObfuscation.storeYn?"1":"0")
                if(this.appObfuscation.osType=='0') form.append("certData", this.certData) //치환된 인증서지문 저장
                // form.append("appTamperDetectYn", this.appObfuscation.appTamperDetectYn?"1":"0")
                // form.append("osTamperDetectYn", this.appObfuscation.osTamperDetectYn?"1":"0")
                // form.append("debugDetectYn", this.appObfuscation.debugDetectYn?"1":"0")

                let payload = form
                try {
                    await this.$store.dispatch('appObfuscation/registerAppObfuscation', payload,{
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    this.appObfuscation.appKey= this.$store.state.appObfuscation.appObfuscation.appKey
                    alert("저장하였습니다.")
                    this.$router.go(-1)
                } catch(error){
                    this.$response(error)
                    this.afterClick = false
                    this.loading = false  // 로딩바 비활성화
                }
            }
            return true
        },
        dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
        },
        dragleave(event) {
            // Clean up
            if (!event.currentTarget.classList.contains('bg-green-300')) {
                event.currentTarget.classList.remove('bg-gray-100');
                event.currentTarget.classList.add('bg-green-300');
            }
        },
        drop(event) {
            event.preventDefault();
            event.currentTarget.classList.remove('bg-gray-100');
            event.currentTarget.classList.add('bg-green-300');
            this.$refs.selectFile.files = event.dataTransfer.files;
            this.onChange(); // Trigger the onChange event manually
            // Clean up
            // event.currentTarget.classList.add('bg-gray-100');
            // event.currentTarget.classList.remove('bg-green-300');
        },
        clear(){
            this.selectMode = false
            this.selectFile = null // 앱정보 초기화
            this.appObfuscation.appNm = '' // 앱 이름 초기화
            this.appObfuscation.appKey = null // 앱 키 초기화
            this.previewImgUrl = null // 앱 아이콘 초기화
            // this.appObfuscation.appTamperDetectYn = true // 앱위변조탐지 여부 초기화
            // this.appObfuscation.osTamperDetectYn = true // OS위변조탐지 여부 초기화
            // this.appObfuscation.debugDetectYn = true // 디버깅탐지 여부 초기화
            this.appObfuscation.certData = '' // 스토어 여부 초기화
        },
        // 앱 다운로드
        async downloadApp(){
            this.loading =true
            await this.$downloadApp({
              url: '/api/apps/app-file/'+this.appObfuscation.appKey, // required
              callback: (data) => { // required
                //console.log('data: ', data)
              },
            })
            this.loading =false
        },
        changeOsType(){
            this.clear()
        },
        validate(){
            const regExp = /[ \{\}\[\]\/?,;:|\)*~`!^\-+┼<>\#$%\'\"\\\(\=]/gi;
            // if (this.appObfuscation.storeYn&&this.appObfuscation.osType=='0'&&!this.$v.appObfuscation.certData.required) {
            //     alert("Google Play 인증서 지문을 입력해주세요.")
            //     return false
            // }

            if(!this.selectFile){
              alert("파일을 선택해 주세요.")
              return false
            }

            if (!this.$v.appObfuscation.appNm.required) {
                alert("앱 이름을 입력해주세요.")
                return false
            }
            else if (this.$v.appObfuscation.appNm.strongAppNm){
                alert("잘못된 입력값입니다.")
                return false
            }


            return true
        },
        // async autoCertData(){
        //     // 패키지명으로 기존 인증서 지문 조회
        //     if(!this.appObfuscation.packageNm){
        //         alert("패키지명을 먼저 입력해주세요.")
        //         return false
        //     }
        //
        //     this.loading=true
        //     try {
        //         await this.$store.dispatch('appObfuscation/getAppCertData', this.appObfuscation.packageNm)
        //         this.certData = this.$store.state.appObfuscation.appCertData.certData
        //         this.appObfuscation.certData = this.$store.state.appObfuscation.appCertData.certData
        //         this.appObfuscation.appNm = this.$store.state.appObfuscation.appCertData.appNm
        //         this.loading=false
        //     } catch(error){
        //         this.$response(error)
        //         this.loading=false
        //     }
        //
        // }
    }
}
</script>
